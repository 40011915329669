$(function() {
  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $("#page-top").fadeOut();
    } else {
      $("#page-top").fadeIn();
    }
  }
  $(window).on("load scroll", displayToTop);

  // ナビゲーション
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('nav').removeClass('open');
      $('.overlay').removeClass('open');
      $('body').removeClass('sp-menu-open');
    } else {
      $(this).addClass('active');
      $(this).next('nav').addClass('open');
      $('.overlay').addClass('open');
      $('body').addClass('sp-menu-open');
    }
  });
  $('.overlay.open, nav').on('click',function(){
    if($(this).hasClass('open')){
      $('.menu-trigger').removeClass('active');
      $('header nav').removeClass('open');
      $('.overlay').removeClass('open');
      $('body').removeClass('sp-menu-open');
      $(this).removeClass('open');
    }
  });

  // ギフト：スライダー
  if ($('body').hasClass('gift')){
    $('.slider').slick({
      autoplay:true,
      autoplaySpeed:5000,
      pauseOnHover: false,
      speed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode:true,
      centerPadding:'0',
      // arrows:false,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },{
        breakpoint: 520,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }
});
    $( window ).on( 'scroll', function() {
    // $( window ).on( 'load resize', function() {
    //   if ($(window).width>992) {
      scrolled = jQuery( window ).scrollTop();
      weight1 = 0.5;
      weight2 = 1.4;
      weight3 = 1.2;
      weight4 = 1.1;
      var footerOff = jQuery( '#siteinfo' ).offset().top;
      // console.log(footerOff);
      jQuery( '#top-parallax1' ).css( 'top', -35 - scrolled * weight1 + 'px' );
      jQuery( '#top-parallax2' ).css( 'top', 750 - scrolled * weight2 + 'px' );
      jQuery( '#top-parallax3' ).css( 'top', 865 - scrolled * weight3 + 'px' );
      var footer1Off = Number(footerOff +130);
      var footer2Off = Number(footerOff + 600);
      var footer3Off = Number(footerOff + 780);

      jQuery( '.home #bottom-parallax1' ).css( 'top', 2450 - scrolled * 1.05 + 'px' );
      jQuery( '.home #bottom-parallax2' ).css( 'top', 2800 - scrolled * weight4 + 'px' );
      jQuery( '.home #parallax1' ).css( 'top', 365 - scrolled * weight3 + 'px' );
      jQuery( '.home #parallax2' ).css( 'top', 230 - scrolled * weight4 + 'px' );
      jQuery( '.home #parallax3' ).css( 'top', 1520 - scrolled * weight4 + 'px' );
      jQuery( '.home #parallax4' ).css( 'top', 934 - scrolled * 1.05 + 'px' );
      jQuery( '.home #parallax5' ).css( 'top', 1760 - scrolled * weight4 + 'px' );
      jQuery( '.home #parallax6' ).css( 'top', 2230 - scrolled *  1.04 + 'px' );

      jQuery( '.about #bottom-parallax1' ).css( 'top', Number(footer1Off) - scrolled * weight4 + 'px' );
      jQuery( '.about #bottom-parallax2' ).css( 'top', Number(footer2Off) - scrolled * weight4 + 'px' );
      jQuery( '.about #parallax1' ).css( 'top', 1480 - scrolled * 1.05 + 'px' );
      jQuery( '.about #parallax2' ).css( 'top', 2200 - scrolled * weight4 + 'px' );
      jQuery( '.about #parallax3' ).css( 'top', 2600 - scrolled * weight3 + 'px' );

      jQuery( '.gift #bottom-parallax1' ).css( 'top', 5760 - scrolled * weight4 + 'px' );
      jQuery( '.gift #bottom-parallax2' ).css( 'top',  6250 - scrolled * weight4 + 'px' );
      jQuery( '.gift #parallax1' ).css( 'top', 1760 - scrolled * weight4 + 'px' );
      jQuery( '.gift #parallax2' ).css( 'top', 2400 - scrolled * weight4 + 'px' );
      jQuery( '.gift #parallax3' ).css( 'top', 4600 - scrolled * weight2 + 'px' );
      jQuery( '.gift #parallax4' ).css( 'top', 4700 - scrolled * weight4 + 'px' );

      jQuery( '.lesson #bottom-parallax1' ).css( 'top', 2800 - scrolled * 1.05 + 'px' );
      jQuery( '.lesson #bottom-parallax2' ).css( 'top',  3290 - scrolled * weight4 + 'px' );
      // jQuery( '.lesson #bottom-parallax3' ).css( 'top', 3410 - scrolled * weight4 + 'px' );
      jQuery( '.lesson #parallax1' ).css( 'top', 1580 - scrolled * weight4 + 'px' );
      jQuery( '.lesson #parallax2' ).css( 'top', 1800 - scrolled * weight4 + 'px' );
      jQuery( '.lesson #parallax3' ).css( 'top', 2900 - scrolled * weight3 + 'px' );
    // }
    // });
});
